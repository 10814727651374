.footer-distributed {
    background-color : #292c2f;
    box-shadow       : 0 1px 1px 0 rgba(0, 0, 0, .12);
    box-sizing       : border-box;
    width            : 100%;
    text-align       : left;
    font             : 700 16px sans-serif;
    padding          : 25px 50px;
    margin-top       : 80px
}

.footer-distributed .footer-center, .footer-distributed .footer-left, .footer-distributed .footer-right {
    display        : inline-block;
    vertical-align : top
}

.footer-distributed .footer-left {
    width : 40%
}

.footer-distributed h3 {
    color  : #fff;
    font   : 400 36px Cookie, cursive;
    margin : 0
}

.footer-distributed h3 span {
    color : #5383d3
}

.footer-distributed .footer-links {
    color   : #fff;
    margin  : 0px 0 12px;
    padding : 0
}

.footer-distributed .footer-links a {
    display         : inline-block;
    line-height     : 1.8;
    text-decoration : none;
    color           : inherit
}

.footer-distributed .footer-company-name {
    color       : #8f9296;
    font-size   : 14px;
    font-weight : 400;
    margin      : 0
}

.footer-distributed .footer-center {
    width : 35%
}

.footer-distributed .footer-center i {
    background-color : #33383b;
    color            : #fff;
    font-size        : 25px;
    width            : 38px;
    height           : 38px;
    border-radius    : 50%;
    text-align       : center;
    line-height      : 42px;
    margin           : 10px 15px;
    vertical-align   : middle
}

.footer-distributed .footer-center i.fa-envelope {
    font-size   : 17px;
    line-height : 38px
}

.footer-distributed .footer-center p {
    display        : inline-block;
    color          : #fff;
    vertical-align : middle;
    margin         : 0
}

.footer-distributed .footer-center p span {
    display     : block;
    font-weight : 400;
    font-size   : 14px;
    line-height : 2
}

.footer-distributed .footer-center p a {
    color           : #5383d3;
    text-decoration : none
}

.footer-distributed .footer-right {
    width : 20%
}

.footer-distributed .footer-company-about {
    line-height : 20px;
    color       : #92999f;
    font-size   : 13px;
    font-weight : 400;
    margin      : 0
}

.footer-distributed .footer-company-about span {
    display       : block;
    color         : #fff;
    font-size     : 14px;
    font-weight   : 700;
    margin-bottom : 20px
}

.footer-distributed .footer-icons {
    margin-top : 25px
}

.footer-distributed .footer-icons a {
    display          : inline-block;
    width            : 35px;
    height           : 35px;
    cursor           : pointer;
    background-color : #343a40 !important;
    border-radius    : 2px;
    font-size        : 20px;
    color            : #fff;
    text-align       : center;
    line-height      : 35px;
    margin-right     : 3px;
    margin-bottom    : 5px
}

@media (max-width : 880px) {
    .footer-distributed {
        font : 700 14px sans-serif
    }

    .footer-distributed .footer-center, .footer-distributed .footer-left, .footer-distributed .footer-right {
        display       : block;
        width         : 100%;
        margin-bottom : 40px;
        text-align    : center
    }

    .footer-distributed .footer-center i {
        margin-left : 0
    }
}

.img-partner{
    height: auto;
}
