@import "variables/colors";
@import "footer";
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

.card-header {
    background-color: $main-color;
    color: white;
    text-align: center;
}

.navbar {
    background-color: $main-color;
    color: white;
}

.nav-link {
    color: white !important;
}

.carousel-item-big {
    max-height: 90vh !important;

    .carousel-caption img {
        width: 60% !important;
    }

    .carousel-caption {
        bottom: 20% !important;
    }
}

.carousel-item-small {
    max-height: 40vh !important;

    .carousel-caption img {
        width: 25% !important;
    }

    .carousel-caption {
        bottom: 10% !important;
    }

    @media (max-width: 768px) {
        .carousel-caption img {
            width: 70% !important;
        }

        .carousel-caption {
            bottom: 5% !important;
        }
    }
}

.carousel-item .image {
    opacity: 0.6;
}


.navbar {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.45);
}

.title-section {
    font-family: 'Roboto', sans-serif;
    color: #4F4F4F;
    font-weight: bold;
    font-size: 50px;
}

.text-section {
    font-family: 'Roboto', sans-serif;
    color: #717171;
    font-weight: normal;
    font-size: 17px;
}

.actuality-resume {
    font-family: 'Roboto', sans-serif;
    color: #717171;
    font-weight: normal;
    font-size: 20px;
}

.agency-name, .title-exemple {
    font-family: 'Roboto', sans-serif;
    color: #4F4F4F;
    font-weight: bold;
    font-size: 27px;
}

.agency-name-pop {
    font-family: 'Roboto', sans-serif;
    color: #717171;
    font-weight: normal;
    font-size: 20px;
}

.agency-coords-pop {
    font-family: 'Roboto', sans-serif;
    color: #4F4F4F;
    font-weight: bold;
    font-size: 15px;
}

.title-exemple {
    font-size: 30px !important;
}

.agency-coords {
    font-family: 'Roboto', sans-serif;
    color: #717171;
    font-weight: normal;
    font-size: 20px;
}


@media (max-width: 768px) {
    .title-section {
        font-size: 40px;
    }
    .text-section {
        font-size: 17px;
    }
    .card-deck {
        display: block !important;
    }
}

.btn-outline-primary {
    color: $main-color;
}

.btn-primary {
    background-color: $main-color;
}

a:hover {
    text-decoration: none;
}

label.active {
    .invalid-feedback {
        margin-top: 2rem !important;
    }


}

